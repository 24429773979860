import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {}, [navigate]);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, []);

  const loginAction = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setValidationErrors({});

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/Customerregistration/customerlogin",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      setIsSubmitting(false);
      debugger;
      // if (response.data && response.data.token)
      if (response.data) {
        localStorage.setItem("usertoken", response.data.token);
        localStorage.setItem("customeruser", email);
        if (rememberMe) {
          localStorage.setItem("rememberEmail", email);
        } else {
          localStorage.removeItem("rememberEmail");
        }
        navigate("/Dashboard");
      } else {
        setValidationErrors({ error: "Username and Password Incorrect" });
        localStorage.removeItem("usertoken");
        localStorage.removeItem("customeruser");
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.response?.data?.errors) {
        setValidationErrors(error.response.data.errors);
      } else if (error.response?.data?.error) {
        setValidationErrors({ error: error.response.data.error });
      } else {
        console.error("Unexpected error:", error.response.data);
        setValidationErrors({ error: error.response.data });
      }
    }
  };

  return (
    <div className="header">
      <div className="container">
        <div className="row innerheader">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-6 d-none d-lg-block bg-login-image">
                        <img
                          src={process.env.PUBLIC_URL + "/login.jpg"}
                          className="loginbg"
                          alt="Login Background"
                        />
                      </div>
                      <div className="col-lg-6">
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Welcome Back!
                            </h1>
                          </div>
                          <form className="user" onSubmit={loginAction}>
                            {validationErrors.error && (
                              <p className="alert alert-danger text-center">
                                <small className="text-danger">
                                  {validationErrors.error}
                                </small>
                              </p>
                            )}
                            <div className="form-group mb-2">
                              <input
                                type="text"
                                className="form-control form-control-user"
                                placeholder="Enter Username..."
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group mb-2">
                              <div className="input-group">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control form-control-user"
                                  placeholder="Enter Password"
                                  id="password"
                                  name="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="p-2 border-0"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    <i
                                      className={`fa ${
                                        showPassword ? "fa-eye-slash" : "fa-eye"
                                      }`}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="custom-control custom-checkbox small">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck"
                                  checked={rememberMe}
                                  onChange={(e) =>
                                    setRememberMe(e.target.checked)
                                  }
                                />
                                &nbsp;{" "}
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck"
                                >
                                  {" "}
                                  Remember Me
                                </label>
                              </div>
                            </div>
                            <button
                              disabled={isSubmitting}
                              type="submit"
                              className="btn btn-primary btn-user btn-block"
                            >
                              Login
                            </button>
                          </form>
                          <hr />
                          <div className="text-center">
                            <Link className="small" to="/Customerregistration">
                              Register Now?
                            </Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Link className="small" to="/ForgotPassword">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
