import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import "../Pages/Subcat.css";
import ImagesForItem from "./proimage";
function Subcat() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const receivedValue = params.get("value");
  const receivedname = params.get("name");
  const [crudOperation, setCrudOperation] = useState("read"); // Default to 'read'
  const [items, setItems] = useState([]);
  const [productitems, setproductItems] = useState([]);
  const [datafactsitem, setdatafactsitem] = useState([]);
  const [productimageitems, setproductimageItems] = useState([]);
  const [catname, setcatname] = useState([]);
  const [cart, setCart] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchItems(receivedValue);
  }, []);

  useEffect(() => {
    fetchItems(receivedValue);
    fetchProductItems(receivedValue);
    fetchdatafacts(receivedValue);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for a smooth scrolling effect
    });
  };
  const fetchProductItems = async (receivedValueid) => {
    try {
      console.log(receivedValue);
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Product/productsubcat?subcat=" +
          receivedValueid +
          "&tokens=" +
          tokens +
          ""
      );
      setproductItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchdatafacts = async (receivedValueid) => {
    try {
      console.log(receivedValue);
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Datafacts/datafactsid?id=" +
          receivedValueid +
          ""
      );
      setdatafactsitem(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const fetchProductimageItems = async (pid) => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Product/productimageall?id=" +
          pid +
          "&tokens=" +
          tokens +
          ""
      );
      setproductimageItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchItems = async (receivedValueid) => {
    try {
      console.log(receivedValue);
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/SubCategory/subcategorydetail?id=" +
          receivedValueid +
          "&tokens=" +
          tokens +
          ""
      );
      setItems(response.data);
      scrollToTop();
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const addToCart = (item) => {
    const updatedCart = [...cart, item];
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart); // Save updated cart to local storage
  };
  const saveCartToLocalStorage = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  return (
    <div className="header">
      <div className="container-fluid ">
        <div className="row  innerheader">
          <div className="col-12 col-md-4">
            <nav className="pull-left">
              {items.map((item, index) => (
                <ol className="breadcrumb" style={{ float: "left" }}>
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li
                    className="breadcrumb-item breadcrumbtext active pull-right toupper"
                    aria-current="page"
                  >
                    <a href="/">{item.name}</a>
                  </li>{" "}
                </ol>
              ))}
            </nav>{" "}
          </div>
          <div className="col col-md-5"></div>
          <div className="col col-md-3 text-right"></div>
        </div>

        {items.map((item, index) => {
          return (
            <>
              <div className="container-fluid">
                <div className="col-12 col-md-12 p-0">
                  <h2 className="card-title mb-2" style={{ fontWeight: "700" }}>
                    {item.name}
                  </h2>
                </div>

                <div className="row mb-0 borderbox">
                  <div className="col col-12 col-md-4 text-right p-0">
                    <img
                      className="catimage"
                      src={
                        "https://api.myonventure.com/api/../images/" +
                        item.image
                      }
                    />
                  </div>
                  <div className="col col-12 col-md-8 p-0">
                    <ul className="subline">
                      <li>
                        <p className=" text-white">
                          <i class="fa-solid fa-circle-right text-white"></i>{" "}
                          {item.topquestion1} : {item.topans1}
                        </p>
                      </li>
                      <li>
                        <p className=" text-white">
                          <i class="fa-solid fa-circle-right text-white"></i>{" "}
                          {item.topquestion2}: {item.topans2}
                        </p>
                      </li>
                    </ul>
                    <div class="sticker-wrapper">
                      <aside class="sticker yellow">
                        <p
                          style={{
                            fontWeight: "700",
                            fontSize: "18px",
                            color: "white",
                          }}
                        >
                          Guaranteed{" "}
                          <i className="fa fa-exclamation-circle text-white"></i>{" "}
                          Best Price
                        </p>
                        <span class="fold"></span>
                      </aside>
                    </div>
                  </div>
                  <div className="col col-12 col-md-12 p-0">
                    <ul class="breadcrumb1">
                      <li>
                        <a href="#">Data and Facts </a>
                      </li>
                      {datafactsitem.map((itemfacts, index) => {
                        return (
                          <>
                            <li className="spstyle">
                              <span className="spstitle">
                                {itemfacts.question}
                              </span>
                              <br />
                              <b> {itemfacts.answer}</b>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12 col-md-8">
                    <div className="row">
                      {productitems.map((item1, index) => {
                        const firstPart = item1.description;
                        return (
                          <>
                            <div
                              className="col col-12 col-md-6 mt-4 mb-0"
                              key={item1.id}
                            >
                              <div className="card cardstyle" key={item1.id}>
                                <ImagesForItem itemId={item1.id} />
                                <div className="card-body">
                                  <h5 className="card-title">{item1.name}</h5>

                                  <p>
                                    {firstPart.substring(0, 100)}{" "}
                                    <Link
                                      to={{
                                        pathname: "/Productdetails",
                                        search: "?value=" + item1.id + "",
                                      }}
                                      className="cardlink"
                                    >
                                      View More...
                                    </Link>
                                  </p>
                                  <div className="bg-light p-1">
                                    {" "}
                                    <button
                                      type="button"
                                      style={{ width: "100%" }}
                                      className="btn btn-success btn-lg btn-block"
                                      onClick={() => addToCart(item1)}
                                    >
                                      <i className="fa-solid fa-cart-shopping text-left"></i>{" "}
                                      ADD TO CART
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div class="three mt-3">
                      <h4>Advertisment Description</h4>
                    </div>
                    <p className="details">{item.details}</p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
export default Subcat;
