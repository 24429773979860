import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Customerregistration.css";

function Customerregistration() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    companyname: "",

    mobile: "",
    address: "",
    pan: "",
    gst: "",
    logo: null,
    city: "",
    state: "",
    country: "1",
    iagree: false,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [errormsg, seterrormsg] = useState("");
  const [successmsg, setsuccessmsg] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        /* if (localStorage.getItem("usertoken")) {
          navigate("/Dashboard");
        } */
        await fetchStateItems();
      } catch (error) {
        console.error("Error during initial data fetch:", error);
      }
    };
    fetchInitialData();
  }, [navigate]);

  const fetchStateItems = async () => {
    try {
      const response = await axios.get(
        "https://api.myonventure.com/api/state/state?tokens=1"
      );
      setStateOptions(response.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCityItems = async (stateId) => {
    try {
      const response = await axios.get(
        `https://api.myonventure.com/api/city/Stateid?id=${stateId}&tokens=1`
      );
      setCityOptions(response.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    setFormData({ ...formData, state: stateId });
    fetchCityItems(stateId);
  };

  const handleCityChange = (e) => {
    setFormData({ ...formData, city: e.target.value });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleLogoChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationErrors({});
    setIsSubmitting(true);

    const headers = { "Content-Type": "multipart/form-data" };
    const postData = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== undefined) {
        postData.append(key, formData[key]);
      }
    });

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerRegistration/Insert",
        postData,
        { headers: headers }
      );

      if (
        response.data ===
        "Your registration was successful. Please check your email to verify your account."
      ) {
        setsuccessmsg(response.data);
        seterrormsg("");
      } else {
        // Handle any specific response errors here
        seterrormsg(response.data);
        setsuccessmsg("");
      }
    } catch (error) {
      seterrormsg(error.response.data);
      setsuccessmsg("");
      console.error("Error during registration:", error);
      if (error.response && error.response.data) {
        setValidationErrors(error.response.data.errors || {});
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="header">
      <div className="container">
        <div className="row innerheader">
          <div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                  <div className="card o-hidden border-0 shadow-lg my-3">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="p-5">
                          <div className="alert alert-primary text-center">
                            <h1 className="h4 text-gray-900 mb-2">
                              Registration!
                            </h1>
                          </div>

                          {errormsg && (
                            <div className="alert alert-danger">{errormsg}</div>
                          )}
                          {successmsg && (
                            <div className="alert alert-success">
                              {successmsg}
                            </div>
                          )}
                          <form className="user" onSubmit={handleSubmit}>
                            {Object.keys(validationErrors).length !== 0 && (
                              <p className="text-center">
                                <small className="text-danger alert alert-danger">
                                  {Object.values(validationErrors).join(", ")}
                                </small>
                              </p>
                            )}

                            <div className="row">
                              {[
                                "email",
                                "password",
                                "companyname",
                                ,
                                "mobile",
                                "address",
                                "pan",
                                "gst",
                              ].map((field) => (
                                <div
                                  className="col-12 col-md-6 form-group"
                                  key={field}
                                >
                                  <label htmlFor={field}>
                                    {field.charAt(0).toUpperCase() +
                                      field.slice(1)}
                                  </label>
                                  <input
                                    type={
                                      field === "password" ? "password" : "text"
                                    }
                                    className="form-control form-control-user"
                                    id={field}
                                    name={field}
                                    placeholder={`Enter ${field}...`}
                                    value={formData[field]}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  {validationErrors[field] && (
                                    <div className="invalid-feedback">
                                      {validationErrors[field]}
                                    </div>
                                  )}
                                </div>
                              ))}

                              <div className="col-12 col-md-6 form-group">
                                <label htmlFor="logo">Company Logo</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="logo"
                                  name="logo"
                                  onChange={handleLogoChange}
                                />
                              </div>

                              <div className="col-12 col-md-6 form-group">
                                <label htmlFor="state">Select State</label>
                                <select
                                  className="form-control"
                                  id="state"
                                  name="state"
                                  value={formData.state}
                                  onChange={handleStateChange}
                                  required
                                >
                                  <option value="">Select an option</option>
                                  {stateOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-12 col-md-6 form-group">
                                <label htmlFor="city">Select City</label>
                                <select
                                  className="form-control"
                                  id="city"
                                  name="city"
                                  value={formData.city}
                                  onChange={handleCityChange}
                                  required
                                >
                                  <option value="">Select an option</option>
                                  {cityOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-12 col-md-6 form-inline p-2">
                                <span>I Agree</span>
                                <input
                                  type="checkbox"
                                  className="chk form-control-user"
                                  id="iagree"
                                  name="iagree"
                                  checked={formData.iagree}
                                  onChange={handleInputChange}
                                  required
                                />
                                <Link
                                  to=""
                                  data-bs-toggle="modal"
                                  data-bs-target="#myModal"
                                >
                                  Terms of use
                                </Link>
                              </div>

                              <div className="col-12 col-md-6 form-group mt-2">
                                <button
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="btn btn-primary btn-user btn-block"
                                >
                                  Register Now
                                </button>
                              </div>
                            </div>
                          </form>

                          <hr />
                          <div className="text-center">
                            Already Registered!{" "}
                            <Link className="small" to="/Login">
                              Login Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms of Use</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                {/* Terms of Use Content */}
                <p>...</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customerregistration;
