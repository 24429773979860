import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const StrategicPlans = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve cart data from localStorage on component mount
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="header">
      <div className="container-fluid ">
        <div className="row  innerheader"></div>
        <div className="container">
          <h2 className="mb-4">
            Tools used By Hamara Brand In Strategy Planning
          </h2>
          <div className="row">
            <ol>
              <li>
                <b> Data analysis </b>:Data analysis in media involves various
                techniques and tools to gather, interpret, and leverage
                information for optimizing campaigns. Here’s how each type of
                data is analyzed:
              </li>
              <br />
              <li>
                <b> Sector Analytics: </b>Purpose: Understand trends, market
                conditions, and competitive landscape.Methods: Analyze industry
                reports, market research studies, and performance metrics. Tools
                include industry databases, market research firms, and trend
                analysis software.
              </li>
              <br />
              <li>
                <b> Target Audience Analytics:</b>Purpose: Identify and
                understand your ideal audience.Methods: Use demographic data,
                psychographic profiles, and behavioral patterns. Data sources
                include surveys, focus groups, social media insights, and CRM
                systems.
              </li>
              <br />
              <li>
                <b> Demographic Analytics:</b>Purpose: Analyze characteristics
                such as age, gender, income, education, and occupation.Methods:
                Segment audience based on demographic criteria using tools like
                Google Analytics, social media insights, and survey data. Create
                profiles to tailor campaigns.
              </li>
              <br />
              <li>
                <b> Geographic Analytics:</b>Purpose: Understand where your
                audience is located.Methods: Use geolocation data, regional
                market research, and mapping tools to analyze audience
                distribution. This helps in targeting specific locations more
                effectively.
              </li>
              <br />
              <li>
                <b> Reader Access Data:</b>Purpose: Assess engagement with print
                and digital publications.Methods: Track metrics such as
                readership numbers, time spent on articles, and interaction
                rates using analytics tools and reader surveys.
              </li>
              <br />
              <li>
                <b> Listener Data:</b>Purpose: Measure engagement with audio
                content like radio and podcasts.Methods: Analyze metrics such as
                listenership numbers, duration, frequency, and demographic
                profiles. Use data from streaming platforms, surveys, and
                listener feedback.
              </li>
              <br />
              <li>
                <b> Viewer Data:</b>Purpose: Evaluate engagement with video
                content.Methods: Track view counts, watch time, completion
                rates, and viewer demographics using video analytics tools and
                platforms like YouTube or TV ratings.
              </li>
              <br />
              <li>
                <b>ROI :</b>ROI(Return on Investment) calculations measure the
                effectiveness and profitability of an investment or campaign.
                ROI, or Return on Investment, is a financial metric used to
                evaluate the profitability or efficiency of an investment. It
                measures the return relative to the cost of the investment,
                providing insight into how effectively resources are being
                utilized to generate profit.
              </li>
              <br />
              <li>
                <b> ROAS:</b> ROAS stands for "Return on Ad Spend". It measures
                the revenue generated for each unit of currency spent on
                advertising. It's a key metric used to evaluate the
                effectiveness of an ad campaign.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicPlans;
