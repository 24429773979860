import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import "../Pages/Faq.css";

function Faq() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const receivedValue = params.get("value");
  const receivedname = params.get("name");

  useEffect(() => {}, []);

  return (
    <div className="header">
      <div className="container-fluid ">
        <div className="row  innerheader">
          <div className="col-12 col-md-4">
            <nav className="pull-left"></nav>{" "}
          </div>
          <div className="col col-md-5"></div>
          <div className="col col-md-3 text-right"></div>
        </div>
        <section class="bsb-faq-3 py-3 py-md-5 py-xl-8 faq">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 class="mb-4 display-5 text-center">
                  Frequently Asked Questions
                </h2>
                <p class="text-secondary text-center lead fs-4">
                  Welcome to our FAQ Section, your one-stop resource for answers
                  to commonly asked questions.
                </p>
                <p class="mb-5 text-center">
                  Whether you're a new customer looking to learn more about what
                  we offer or a long-time user seeking clarification on specific
                  topics, this section has clear and concise information about
                  our products and services.
                </p>
                <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-11 col-xl-10">
                  <div class="d-flex align-items-end mb-5">
                    <i class="bi bi-person-gear me-3 lh-1 display-5"></i>
                    <h3 class="m-0 textgreen">FAQ Section</h3>
                  </div>
                </div>
                <div class="col-11 col-xl-10">
                  <div class="accordion accordion-flush" id="faqAccount">
                    <div class="accordion-item bg-transparent border-top border-bottom py-3">
                      <h2 class="accordion-header" id="faqAccountHeading1">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse1"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse1"
                        >
                          Is Hamarabrand platform or Agency ?
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse1"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading1"
                      >
                        <div class="accordion-body">
                          <p>
                            No, Hamara Brand media ecosystem of Myon Venture
                            pvt. Ltd is not a traditional agency. It functions
                            as a media buying and selling platform, offering
                            comprehensive media coverage and solutions across
                            various channels, including district-wise coverage
                            in India. We facilitate media planning and
                            execution, providing a range of services to help you
                            manage and optimize your media investments
                            effectively.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqAccountHeading2">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse2"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse2"
                        >
                          What is media buying, and how does it work?
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse2"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading2"
                      >
                        <div class="accordion-body">
                          <p>
                            Media buying involves purchasing advertising space
                            across various channels, such as TV, radio, print,
                            digital, and social media, to promote your brand or
                            product. The process includes negotiating prices,
                            determining the best time slots, and ensuring your
                            ads reach your target audience effectively.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqAccountHeading3">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse3"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse3"
                        >
                          How do you decide which media channels are best for my
                          campaign?
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse3"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading3"
                      >
                        <div class="accordion-body">
                          <p>
                            We assess your brand’s goals, target audience,
                            budget, and campaign objectives. Based on this, we
                            select media channels that offer the best reach,
                            engagement, and return on investment for your
                            specific needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqAccountHeading4">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse4"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse4"
                        >
                          What is the difference between media buying and media
                          planning?
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse4"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading4"
                      >
                        <div class="accordion-body">
                          <p>
                            Media planning involves strategizing which media
                            channels and platforms to use to best reach your
                            target audience. Media buying is the next step,
                            where we purchase the ad space based on the plan,
                            negotiate rates, and manage the placement and timing
                            of your ads.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqAccountHeading5">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqAccountCollapse5"
                          aria-expanded="false"
                          aria-controls="faqAccountCollapse5"
                        >
                          How do you measure the success of a media campaign?
                        </button>
                      </h2>
                      <div
                        id="faqAccountCollapse5"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqAccountHeading5"
                      >
                        <div class="accordion-body">
                          <p>
                            We track key performance indicators (KPIs) such as
                            reach, impressions, click-through rates, conversion
                            rates, and return on investment (ROI). We also
                            analyze audience engagement and feedback to gauge
                            the effectiveness of the campaign.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-11 col-xl-10">
                  <div class="accordion accordion-flush" id="faqOrder">
                    <div class="accordion-item bg-transparent border-top border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading1">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse1"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse1"
                        >
                          Can you help me with district-wise media coverage?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse1"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading1"
                      >
                        <div class="accordion-body">
                          <p>
                            Yes, Hamara Brand offers specialized services with
                            district-wise media coverage across India. We ensure
                            that your campaign reaches the right audience in
                            specific regions, maximizing your campaign’s local
                            impact.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading2">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse2"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse2"
                        >
                          What is the cost structure for media buying?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse2"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading2"
                      >
                        <div class="accordion-body">
                          <p>
                            The cost of media buying depends on several factors,
                            including the type of media, the duration of the
                            campaign, the time slots, and the audience reach. We
                            work within your budget to get the best rates and
                            highest value for your investment.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading3">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse3"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse3"
                        >
                          How do you ensure that my ads are seen by the right
                          audience?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse3"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading3"
                      >
                        <div class="accordion-body">
                          <p>
                            We use detailed audience analysis and data-driven
                            targeting strategies. This includes demographic,
                            geographic, and behavioral data to ensure your ads
                            reach the most relevant audience segments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading4">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse4"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse4"
                        >
                          What are the latest trends in media buying that I
                          should be aware of?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse4"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading4"
                      >
                        <div class="accordion-body">
                          <p>
                            Current trends include programmatic advertising,
                            personalized ads, the rise of digital and social
                            media platforms, and an increasing focus on data
                            analytics to optimize media buys. Our team stays
                            updated on these trends to offer the most effective
                            solutions for your campaign.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading5">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse5"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse5"
                        >
                          How does Hamara Brand differentiate itself in media
                          buying and selling?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse5"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading5"
                      >
                        <div class="accordion-body">
                          <p>
                            Hamara Brand is the first platform in India to
                            provide district-wise media coverage across the
                            country directly from media owners , ensuring
                            precise targeting. We leverage our deep industry
                            connections, cutting-edge technology, and
                            experienced Brand Managers to deliver customized
                            media solutions that drive results.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading6">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse6"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse6"
                        >
                          Why Media planning is required?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse6"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading6"
                      >
                        <div class="accordion-body">
                          <p>
                            "Media planning is crucial for targeting the right
                            audience, optimizing your budget, ensuring
                            consistent messaging, and measuring campaign
                            performance. It helps you reach your goals
                            efficiently and effectively."
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading7">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse7"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse7"
                        >
                          Can i plan media in low budget also with Hamarabrand?{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse7"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading7"
                      >
                        <div class="accordion-body">
                          <p>
                            Yes, you can plan media on a low budget with Hanara
                            Brand. We specialize in creating cost-effective
                            strategies tailored to your needs, ensuring you get
                            the most value from your budget while achieving your
                            marketing goals.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading8">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse8"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse8"
                        >
                          What if my campaign doesn’t perform as expected?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse8"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading8"
                      >
                        <div class="accordion-body">
                          <p>
                            We continuously monitor and optimize your campaign
                            in real-time, making adjustments as needed to
                            improve performance. If necessary, we re-strategize
                            to ensure your goals are met.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading9">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse9"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse9"
                        >
                          Why Choose Hamara Brand for Your Media Planning Needs?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse9"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading9"
                      >
                        <div class="accordion-body">
                          <p>
                            "Choosing Hamara Brand for expert media planning
                            that delivers results. With over a decade of
                            experience and 360° solutions tailored to your
                            brand, we offer district-wise coverage across India,
                            data-driven strategies, and cost-effective
                            campaigns. Let us maximize your ROI and drive your
                            brand's growth."
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading10">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse10"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse10"
                        >
                          Is My Payment transaction are Safe?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse10"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading10"
                      >
                        <div class="accordion-body">
                          <p>
                            "Yes, your online transactions with Hanara Brand are
                            completely safe. We use advanced encryption
                            technologies and secure payment gateways to protect
                            your data and ensure that your financial information
                            remains confidential. Your security and trust are
                            our top priorities."
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading11">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse11"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse11"
                        >
                          If you’re a startup looking for Brand Building
                          support?{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse11"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading11"
                      >
                        <div class="accordion-body">
                          <p>
                            Here’s how Hamara Brand can help:"As a startup, we
                            understand the unique idea and thier challenges you
                            face in building your brand from the ground up.
                            Hamara Brand offers tailored media planning and
                            buying solutions that fit your budget and growth
                            goals. We help you maximize your reach with
                            strategic 360° planning, including district-wise
                            expansion targeting across India, ensuring your
                            message reaches the right audience.Our team provides
                            expert guidance every step of the way, from crafting
                            a compelling brand identity to executing impactful
                            campaigns that drive results. Whether you're looking
                            to establish your presence locally or expand
                            nationally, we’re here to help you grow efficiently
                            and effectively."
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading12">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse12"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse12"
                        >
                          What is ATL Stands for?{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse12"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading12"
                      >
                        <div class="accordion-body">
                          <p>
                            ATL stands for "Above The Line" in media. It refers
                            to traditional mass media advertising methods such
                            as TV, radio, print (newspapers and magazines), and
                            outdoor (billboards). ATL advertising aims to reach
                            a large audience and build brand awareness on a
                            broad scale, focusing on brand building and reaching
                            a wide demographic.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading13">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse13"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse13"
                        >
                          What's BTL stand For.{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse13"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading13"
                      >
                        <div class="accordion-body">
                          <p>
                            BTL stands for "Below The Line." It refers to
                            marketing and promotional activities that are more
                            targeted and direct compared to ATL (Above The Line)
                            methods. BTL strategies include activities like
                            direct mail, sponsorships, events, trade shows,
                            experiential marketing, and personal selling. These
                            approaches focus on engaging with specific audience
                            segments, creating personalized interactions, and
                            driving direct responses.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading14">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse14"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse14"
                        >
                          What is Transit Media{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse14"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading6"
                      >
                        <div class="accordion-body">
                          <p>
                            Transit media refers to advertising placed on or
                            around various forms of public transportation. This
                            includes ads on buses, trains, taxis, subways, and
                            their stations. Transit media can take the form of
                            exterior wraps, interior posters, digital screens,
                            and other promotional materials. It’s effective for
                            reaching a large and diverse audience, often in
                            high-traffic areas, and can create significant
                            visibility for brands.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading15">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse15"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse15"
                        >
                          What's is Difference in Brand Manager and Marketing
                          Manager{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse15"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading15"
                      >
                        <div class="accordion-body">
                          <p>
                            Brand Manager and Marketing Manager roles have
                            overlapping responsibilities but differ in their
                            focus and scope:
                            <br />
                            Brand Manager:Focus: Primarily responsible for the
                            development, maintenance, and enhancement of a
                            specific brand’s image and identity.
                            <br />
                            Responsibilities: Includes managing brand strategy,
                            brand positioning, product development, and ensuring
                            brand consistency across all channels. Also involves
                            market research to understand consumer perceptions
                            and adjust brand messaging.
                            <br />
                            Goal: Build and maintain a strong, cohesive brand
                            identity and ensure that all branding efforts align
                            with the brand’s long-term goals.
                            <br />
                            Marketing Manager:
                            <br />
                            Focus: Oversees and executes broader marketing
                            strategies and campaigns that may encompass multiple
                            brands or products.
                            <br />
                            Responsibilities: Includes planning and executing
                            marketing campaigns, managing budgets, coordinating
                            with sales and other departments, and analyzing
                            campaign performance. The role often involves
                            tactical aspects of marketing, such as digital
                            marketing, promotions, and market segmentation.
                            <br />
                            Goal: Drive demand and increase sales through
                            effective marketing strategies and campaigns.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading16">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse16"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse16"
                        >
                          Why we trust Hamarabrand as compare to our traditional
                          agency model?
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse16"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading16"
                      >
                        <div class="accordion-body">
                          <p>
                            1. **Direct Access:** Get direct access to a wide
                            range of media options with thier owners with better
                            transparency.
                            <br />
                            2. **Specialized Coverage:** Benefit from
                            district-wise media coverage across India.
                            <br />
                            3. **Data-Driven:** Utilize advanced analytics for
                            optimized media spend.
                            <br />
                            4. **Cost-Effective:** Achieve your goals within
                            budget, avoiding traditional agency overheads.
                            <br />
                            5. **Transparency:** Enjoy clear visibility into
                            media performance and costs.
                            <br />
                            6. **Customized:** Receive tailored media strategies
                            specific to your needs.
                            <br />
                            7. **Efficiency:** Streamlined and flexible media
                            management for better results.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading17">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse17"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse17"
                        >
                          Will i get agency data also or only media owners data
                          on Hamara Brand?{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse17"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading17"
                      >
                        <div class="accordion-body">
                          <p>
                            On Hamara Brand, you primarily receive media owners'
                            data, which includes information about various media
                            options available for purchase, such as TV, radio,
                            print, and digital channels. This data helps you
                            make informed decisions about where to place your
                            ads.
                            <br />
                            For agency-specific data, Hamara Brand recommended
                            agencies that qualified our selection matrix and
                            also align with your needs and goals, based on their
                            expertise, performance, and suitability for your
                            media planning requirements. We can suggest agencies
                            with proven track records and strong capabilities in
                            areas relevant to your campaign.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item bg-transparent border-bottom py-3">
                      <h2 class="accordion-header" id="faqOrderHeading18">
                        <button
                          class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqOrderCollapse18"
                          aria-expanded="false"
                          aria-controls="faqOrderCollapse18"
                        >
                          Can I buy media in other countries also from Hamara
                          Brand ?{" "}
                        </button>
                      </h2>
                      <div
                        id="faqOrderCollapse18"
                        class="accordion-collapse collapse"
                        aria-labelledby="faqOrderHeading18"
                      >
                        <div class="accordion-body">
                          <p>
                            Currently Not! Hamara Brand primarily focuses on
                            media buying and selling within India, including
                            district-wise coverage across the country. For media
                            buying in other countries, Hamara Brand is in
                            process for doing tieups on international level.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Faq;
