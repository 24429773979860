import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleLogout();
  });
  const handleLogout = () => {
    // Remove user data from local storage
    localStorage.removeItem("customeruser");
    localStorage.setItem("customeruser", "");
    // Optionally clear any other session-related data

    // Navigate to the login page
    navigate("/Login");
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row innerheader">
          <div className="logout-container">
            <h2>Logging you out...</h2>
            <p>Please wait while we log you out.</p>
            {/* Trigger logout on component mount */}
            {handleLogout()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
