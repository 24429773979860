import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {
    // Retrieve cart data from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userToken = localStorage.getItem("customeruser");
    if (!userToken) {
      navigate("/Login");
      return; // Exit early if the user is not logged in
    }

    const headers = { "Content-Type": "application/json" };
    const requestBody = {
      Email: userToken,
      Token: "1", // Include the token if necessary
    };

    try {
      const response1 = await axios.post(
        "https://api.myonventure.com/api/CustomerRegistration/Customerbyuser",
        requestBody,
        { headers }
      );

      const customerData = response1.data;

      if (customerData && customerData.length > 0) {
        const customerId = customerData[0].id; // Adjust based on your response structure
        console.log("Customer ID:", customerId);
        console.log("Cart Items:", cart);

        const postData = cart.map((item) => ({
          Orderno: 0,
          Customerid: customerId,
          Product: item.id,
          quantity: 1,
          price: item.rate,
        }));

        console.log("Post Data:", postData);

        const response = await axios.post(
          "https://api.myonventure.com/api/Customerorder/Insert",
          postData, // Directly send the array of items
          { headers }
        );

        console.log("Order placed successfully:", response.data);

        // Clear cart and navigate to a confirmation page or home page
        localStorage.removeItem("cart");
        setCart([]); // Clear the cart state
        navigate("/Dashboard"); // Navigate to the home page
      } else {
        console.error("No customer data found.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error appropriately (e.g., show a notification)
    }
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row innerheader"></div>
        <div className="container">
          <h2 className="mb-4">Checkout</h2>
          <div className="row">
            <div className="col-md-12">
              <h4>Order Summary</h4>
              <ul className="list-group mb-3">
                {cart.length > 0 ? (
                  cart.map((item, index) => (
                    <li key={index} className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <div style={{ width: "50px" }}>{index + 1}.</div>
                        <div style={{ textAlign: "left", width: "150px" }}>
                          {item.name}
                        </div>
                        <div>{item.rate}</div>
                        <div>
                          <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => removeFromCart(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-group-item">Your cart is empty</li>
                )}
              </ul>
              <h5>
                Total:{" "}
                {cart.reduce((acc, item) => acc + item.rate, 0).toFixed(2)}
              </h5>
            </div>
            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                <i className="fa fa-arrow-right"></i> BUY NOW
              </button>{" "}
              <Link to="/Home" className="btn btn-danger">
                <i className="fa fa-undo"></i> Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
