import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Ensure axios is imported
import "./Dashboard.css";
import Customermenu from "./Customermenu";

const Dashboard = () => {
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalamountspent, setTotalspent] = useState(0);
  const navigate = useNavigate();
  const userToken = localStorage.getItem("customeruser");

  useEffect(() => {
    console.log(userToken);
    if (userToken === "") {
      navigate("/Login");
      // return; // Exit early if the user is not logged in
    }
    fetchItems();
    totalspent();
  }, [userToken, navigate]); // Dependencies include userToken and navigate

  const fetchItems = async () => {
    const headers = { "Content-Type": "application/json" };
    const requestBody = {
      tbl: "CustomerOrder",
      query: `where Customerid in(select id from customerregistration where email='${userToken}')`,
      result: "Count(*) as totalorder",
      Token: "1", // Include the token if necessary
    };

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerReport/getdata",
        requestBody,
        { headers }
      );

      setTotalOrder(response.data[0].totalorder); // Update state with total orders
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const totalspent = async () => {
    const headers = { "Content-Type": "application/json" };
    const requestBody = {
      tbl: "CustomerOrder",
      query: `where Customerid in(select id from customerregistration where email='${userToken}')`,
      result: "Sum(COALESCE(price, 0)) as totalamountspent",
      Token: "1", // Include the token if necessary
    };

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerReport/getdata",
        requestBody,
        { headers }
      );

      setTotalspent(response.data[0].totalamountspent); // Update state with total orders
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row innerheader">
          <div className="dashboard">
            <Customermenu />
            <div className="main-content">
              <h1>Welcome to Your Dashboard</h1>
              <div className="summary">
                <div className="card1">
                  <div class="row mb-3">
                    <div class="col-xl-3 col-sm-6 py-2">
                      <div class="card bg-success text-white h-100">
                        <div class="card-body bg-info">
                          <div class="rotate">
                            <i class="fa fa-shopping-cart fa-4x"></i>
                          </div>
                          <h6 class="text-uppercase">Total Orders</h6>
                          <h1 class="display-4">{totalOrder}</h1>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 py-2">
                      <div class="card text-white bg-danger h-100">
                        <div class="card-body bg-success">
                          <div class="rotate">
                            <i class="fa fa-dollar fa-4x"></i>
                          </div>
                          <h6 class="text-uppercase">Total Amount Spent</h6>
                          <h1 class="display-4">{totalamountspent}</h1>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 py-2">
                      <div class="card text-white bg-info h-100">
                        <div class="card-body bg-info">
                          <div class="rotate">
                            <i class="fa fa-twitter fa-4x"></i>
                          </div>
                          <h6 class="text-uppercase">Tweets</h6>
                          <h1 class="display-4">125</h1>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 py-2">
                      <div class="card text-white bg-warning h-100">
                        <div class="card-body">
                          <div class="rotate">
                            <i class="fa fa-share fa-4x"></i>
                          </div>
                          <h6 class="text-uppercase">Shares</h6>
                          <h1 class="display-4">36</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Uncomment for recent activities
              <div className="recent-activities">
                <h3>Recent Activity</h3>
                <ul>
                  <li>User John Doe signed in</li>
                  <li>Order #1234 was processed</li>
                  <li>New user Jane Smith registered</li>
                </ul>
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
