// src/EnquiryForm.js

import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";

Modal.setAppElement("#root"); // Accessibility setting

const EnquiryForm = ({ isOpen, onRequestClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://myonventure.com/api/CustomerEnquiry/SubmitEnquiry",
        {
          Name: name,
          Email: email,
          Mobile: mobile,
          Message: message,
        }
      );
      console.log(response.data);
      // Reset form
      setName("");
      setEmail("");
      setMobile("");
      setMessage("");
      onRequestClose(); // Close modal after submission
    } catch (err) {
      console.error("Error submitting enquiry:", err);
      setError("Failed to submit enquiry. Please try again.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Enquiry Form"
      className="homepop "
    >
      <div className="row">
        <div className="col col-10 p-0">
          <h2>Enquiry Form</h2>
        </div>
        {/*    <div className="col col-2 p-2 text-right"> &nbsp;</div>{" "} */}
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col col-6 p-2">Name: </div>
          <div className="col col-6  p-2">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="col col-6  p-2">Email: </div>
          <div className="col col-6  p-2">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="col col-6  p-2">Mobile: </div>
          <div className="col col-6  p-2">
            <input
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
              pattern="[0-9]{10}"
              placeholder="1234567890"
            />
          </div>
          <div className="col col-6 p-2">Message: </div>
          <div className="col col-6  p-2">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <div className="col col-12 p-2 text-center">
            <button
              type="submit"
              className="btn bggreen text-white text-bold btn-block"
              style={{ width: "100%" }}
            >
              Submit
            </button>
            <br />
            <br />
            <a
              style={{ color: "GrayText", cursor: "pointer" }}
              onClick={onRequestClose}
            >
              Maybe Later
            </a>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EnquiryForm;
