import { Route, Routes } from "react-router-dom";
import "./App.css";

import About from "./Pages/About.js";
import Contact from "./Pages/Contact.js";
import Services from "./Pages/Services.js";
import Category from "./Pages/Category.js";
import Home from "./Pages/Home.js";
import Header from "./componant/header.js";
import Footer from "./componant/footer.js";

import Subcat from "./Pages/Subcat.js";
import CheckoutPage from "./Pages/CheckoutPage.js";
import Productdetails from "./Pages/Productdetails.js";
import Login from "./Pages/Login.js";
import Dashboard from "./Pages/Dashboard.js";
import Customerregistration from "./Pages/Customerregistration.js";
import Privacypolicy from "./Pages/Privacypolicy.js";
import Termsofuse from "./Pages/Termsofuse.js";
import { ScrollRestoration } from "react-router-dom";
import ForgotPassword from "./Pages/ForgotPassword";
import Changepassword from "./Pages/Changepassword.js";
import Faq from "./Pages/Faq.js";
import Findingmedia from "./Pages/Findingmedia.js";
import Verifyemail from "./Pages/Verifyemail.js";
import Profileupdate from "./Pages/Profileupdate.js";
import Customerorder from "./Pages/Customerorder.js";
import Logout from "./Pages/Logout.js";
import ProtectedRoute from "./Pages/ProtectedRoute.js"; // Import the ProtectedRoute component
import Mediaregistration from "./Pages/Mediaregistration.js";
import StrategicPlans from "./Pages/StrategicPlans.js";
import MediaPlanning from "./Pages/MediaPlanning.js";
import PRPlanning from "./Pages/PRPlanning.js";
import CelebrityEndorsement from "./Pages/CelebrityEndorsement.js";
import EventsPlanning from "./Pages/EventsPlanning.js";
import StartupPlanning from "./Pages/StartupPlanning.js";
function App() {
  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/StrategicPlans" element={<StrategicPlans />} />
        <Route path="/MediaPlanning" element={<MediaPlanning />} />
        <Route path="/PRPlanning" element={<PRPlanning />} />
        <Route
          path="/CelebrityEndorsement"
          element={<CelebrityEndorsement />}
        />
        <Route path="/StartupPlanning" element={<StartupPlanning />} />
        <Route path="/EventsPlanning" element={<EventsPlanning />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/Subcat" element={<Subcat />} />
        <Route path="/Productdetails" element={<Productdetails />} />
        <Route path="/CheckoutPage" element={<CheckoutPage />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Logout" element={<Logout />} />

        {/* Protected Route for Dashboard */}
        <Route
          path="/Dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Customerregistration"
          element={<Customerregistration />}
        />
        <Route path="/Mediaregistration" element={<Mediaregistration />} />

        <Route path="/Termsofuse" element={<Termsofuse />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Changepassword" element={<Changepassword />} />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/Findingmedia" element={<Findingmedia />} />
        <Route path="/Verifyemail" element={<Verifyemail />} />
        <Route path="/Profileupdate" element={<Profileupdate />} />
        <Route path="/Customerorder" element={<Customerorder />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
