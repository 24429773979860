import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon component

import "../Pages/Home.css";
import $ from "jquery";
import Category from "./Category";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import Agencyslider from "react-slick";
import Testimonial from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnquiryForm from "../Pages/EnquiryForm";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Testi_sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Home() {
  const [value, setValue] = useState("");
  const [crudOperation, setCrudOperation] = useState("read"); // Default to 'read'
  const [items, setItems] = useState([]);
  const [clientlogoitems, setclientlogoItems] = useState([]);
  const [agencylogoitems, setagencylogoItems] = useState([]);
  const [adhometop1, setadhometop1] = useState([]);
  const [adhome2, setadhome2] = useState([]);
  const [cityoptions, setcityOptions] = useState([]);
  const [selectedcityOption, setcitySelectedOption] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Automatically open the modal on page load

  useEffect(() => {
    setIsModalOpen(true);
    fetch();
    fetchcity();
    fetchadhometop1();
    fetchadhome2();
    fetchItems();
    fetchclientlogo();
    fetchagencylogo();
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchItems = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Categories/categoryall?tokens=" +
          tokens +
          ""
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchclientlogo = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Clientlogo/clientlogoall?tokens=" +
          tokens +
          ""
      );
      setclientlogoItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchagencylogo = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Agencylogo/agencylogoall?tokens=" +
          tokens +
          ""
      );
      setagencylogoItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchadhometop1 = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Advertisement/Advertisementlocation?id=1&tokens=" +
          tokens +
          ""
      );
      setadhometop1(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchadhome2 = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Advertisement/Advertisementlocation?id=2&tokens=" +
          tokens +
          ""
      );
      setadhome2(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const fetch = async () => {
    const response1 = await axios.get(
      "https://api.myonventure.com/api/Categories/categoryall?tokens=1"
    );

    // Update state with fetched data
    setOptions(response1.data);
  };
  const fetchcity = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/City/city?tokens=" + tokens + ""
      );
      setcityOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const setData = async (id) => {
    console.log(id);
    setSelectedOption(id);

    // setFormData({ ...formData, category: id });
  };

  const setcityData = async (id) => {
    setcitySelectedOption(id);

    // setFormData({ ...formData, city: id });
  };

  const handleSearch = async () => {
    console.log(selectedOption);
    window.location.href = `/Category?category=${selectedOption}&city=${selectedcityOption}&qry=2`;
  };

  const phoneNumber = "9571115669"; // Replace with the actual number
  const message = "Hello, I have an Enquiry."; // Your message
  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  const handleClick = () => {
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <a
        className="whatsapp_float"
        style={{ position: "fixed", bottom: 0, zIndex: "1", right: 0 }}
        onClick={handleClick}
      >
        <i class="fab fa-whatsapp  whatsapp-icon"></i>
      </a>
      <EnquiryForm isOpen={isModalOpen} onRequestClose={closeModal} />
      <div className="container-fluid bgback">
        <div className="row header  bgimg">
          <div className="overlay">
            <div className="container">
              <div className="row">
                {/*  <div className="col col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
           <div className="container-fluid d-flex empower">   <h1>Universal Market place access to advertising</h1>
</div></div> */}
                <div className="col col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">
                  <div className="container-fluid d-flex find">
                    <div>
                      <br />
                      <i class="fas fa-search m-1 me-md-2 textpink fa-3x"></i>
                    </div>
                    <div>
                      <br />
                      <h1 className="textpink">EXPLORE</h1>
                      Explore the ocean of media.
                      <br />
                      <a href="">
                        Inventory Price{" "}
                        <i class="fas fa-angle-right m-1 me-md-2 bggreen text-white  rounded-circle"></i>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="col col-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4  ">
                  <div className="container-fluid d-flex plan">
                    <div>
                      <br />
                      <i class="fas fa-calendar m-1 me-md-2 textpink fa-3x"></i>
                    </div>
                    <div>
                      <br />
                      <h1 className="textpink">SCHEDULE</h1>
                      Plan your media with ROAS Calculation
                      <br />
                      <a href="">
                        Media Budget
                        <i class="fas fa-angle-right m-1 me-md-2 bggreen text-white  rounded-circle"></i>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="col col-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">
                  <div className="container-fluid d-flex buy">
                    <div>
                      <br />
                      <i class="fas fa-shopping-cart m-1 me-md-2 textpink fa-3x"></i>
                    </div>
                    <div>
                      <br />
                      <h1 className="textpink">Buy Now</h1>
                      To decide and complete your purchase with confidence &
                      trust.
                      <br />
                      <a href="">
                        Media Spots{" "}
                        <i class="fas fa-angle-right m-1 me-md-2 bggreen text-white  rounded-circle"></i>
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-2">
                  <div className="searchbox w-50 m-auto">
                    <div className="row">
                      <div className="col col-6  bg-light border-end  col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">
                        <div className="container-fluid d-flex ct">
                          {" "}
                          <i
                            className="fa fa-map-marker textpink "
                            style={{ marginTop: "12px" }}
                          ></i>
                          <select
                            className="form-control  border-0 bg-transparent"
                            value={selectedcityOption}
                            onChange={(e) => setcityData(e.target.value)}
                          >
                            <option value="">Select City</option>
                            {cityoptions.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col col-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5 ">
                        <div className="container-fluid d-flex cat">
                          {" "}
                          <select
                            className="form-control  border-0 bg-transparent"
                            value={selectedOption}
                            onChange={(e) => setData(e.target.value)}
                          >
                            <option value="">Select Category</option>
                            {options.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col col-12 bgpink  col-md-3 col-lg-3 col-xl-3 col-xxl-3 scrh">
                        <button
                          className="btn  bgpink btn-block text-white "
                          onClick={handleSearch}
                        >
                          <i className="fa fa-search"></i> SEARCH
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid contain">
          <div className="para  text-black">
            <div className="container-fluid mt-2">
              <div
                id="adhometop1"
                class="carousel slide mt-0"
                data-bs-ride="carousel "
              >
                <div class="carousel-indicators">
                  {adhometop1.map((image1, index1) => (
                    <>
                      <button
                        type="button"
                        data-bs-target="#adhometop1"
                        data-bs-slide-to={index1}
                        key={index1}
                        className={index1 === 0 ? "active" : ""}
                      ></button>
                    </>
                  ))}
                </div>
                <div class="carousel-inner">
                  {adhometop1.map((item, index) => (
                    <div
                      key={item.id || index}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <img
                        className="d-block w-100 topad"
                        src={`https://api.myonventure.com/api/../Advertisement/${item.image}`}
                        alt={`Slide ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#adhometop1"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#adhometop1"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
            <div className="container-fluid adcategory ">
              <h4 className="cstmdisplay-6"> Search Media Category</h4>
              <p
                className="text-secondary mb-3 text-left"
                style={{ textAlign: "left" }}
              >
                Reach your audience everywhere with our smart ads!
              </p>
              <div class="row row-cols-6 g-3">
                {items.map((items) => {
                  return (
                    <>
                      <div class="col  col-6 col-md-2">
                        <div class="card">
                          <div class="card-body">
                            <Link
                              to={{
                                pathname: "/Category",
                                search:
                                  "?category=" +
                                  items.id +
                                  "&name=" +
                                  items.name +
                                  "&qry=1",
                              }}
                            >
                              <i
                                className={`fas fa-${items.icon} m-1 me-md-2 textgreen`}
                              ></i>
                              <h6 class="card-title">{items.name}</h6>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="container-fluid whyus  text-white">
              <h1>Hamara Brand</h1>
              <h6>(The Brand Manager Venture)</h6>
              <p>
                {" "}
                Hamara Brand is a trailblazing platform in the media industry,
                recognized as the first in India.
              </p>
              <Link
                to="/About"
                className="btn bgpink btn-sm text-white text-bold"
              >
                LEARN MORE{" "}
                <i class="fas fa-angle-right m-1 me-md-2 bgpink p-1 text-white  rounded-circle"></i>
              </Link>
            </div>

            <section class="py-4 bg-white py-xl-8">
              <div class="container">
                <div class="row ">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-7 col-xxl-6">
                    <h2 class="mb-2 cstmdisplay-6 text-left">
                      The Finest Media Platform
                    </h2>
                    <p class="text-secondary mb-3 text-left">
                      You convey the idea, and we deliver a refined interface.
                    </p>
                    {/*   <hr class="w-100 mx-auto mb-2  border-dark-subtle" /> */}
                  </div>
                </div>
              </div>

              <div class="container overflow-hidden">
                <div class="row gy-5 gy-md-6 gy-lg-0">
                  <div class="col-6 col-lg-3">
                    <div class="text-center">
                      <div class="d-flex align-items-center justify-content-center bgpink mb-3 mx-auto bsb-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          fill="currentColor"
                          class="bi bi-people text-white"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                        </svg>
                      </div>
                      <h5 class="display-6 fw-bold m-1">700+</h5>
                      <p class="text-secondary m-0">Happy Customers</p>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3">
                    <div class="text-center">
                      <div class="d-flex align-items-center justify-content-center bgpink mb-3 mx-auto bsb-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          fill="currentColor"
                          class="bi bi-activity text-white"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"
                          />
                        </svg>
                      </div>
                      <h5 class="display-6 fw-bold m-1">80+</h5>
                      <p class="text-secondary m-0">Team Members</p>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3">
                    <div class="text-center">
                      <div class="d-flex align-items-center justify-content-center bgpink mb-3 mx-auto bsb-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          fill="currentColor"
                          class="bi bi-briefcase text-white"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </div>
                      <h5 class="display-6 fw-bold m-1">500+</h5>
                      <p class="text-secondary m-0">Finished Projects</p>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3">
                    <div class="text-center">
                      <div class="d-flex align-items-center justify-content-center bgpink mb-3 mx-auto bsb-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          fill="currentColor"
                          class="bi bi-award text-white"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                          <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                        </svg>
                      </div>
                      <h5 class="display-6 fw-bold m-1">5+</h5>
                      <p class="text-secondary m-0">Awards Winning</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="py-3 py-md-3 mt-3 mb-4 py-xl-8 ">
              <div class="container">
                <div class="row ">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-7 col-xxl-6">
                    <h2 class="mb-2 cstmdisplay-6 text-left text-bold">
                      Clients
                    </h2>
                    <p class="text-secondary mb-3 text-left">
                      Enhancing their visual presence and market appeal.
                    </p>
                    {/*  <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" /> */}
                  </div>
                </div>
              </div>

              <div class="container overflow-hidden">
                <div class="row gy-5">
                  <Slider {...sliderSettings} className="customer-logos">
                    {clientlogoitems.map((items) => {
                      return (
                        <>
                          <div class="slide">
                            <img
                              className="imglogo"
                              width="125"
                              height="80"
                              src={
                                "https://api.myonventure.com/api/../clientlogo/" +
                                items.image
                              }
                            />
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </section>
            <section class="py-1 py-md-3 mb-4 py-xl-8">
              <div class="container">
                <div class="row ">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-7 col-xxl-6">
                    <h2 class="mb-1 cstmdisplay-6 text-left">
                      Recommended Agencies
                    </h2>
                    <p class="text-secondary mb-3 text-left">
                      Our best Associates.
                    </p>
                    {/*    <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" /> */}
                  </div>
                </div>
              </div>

              <div class="container overflow-hidden">
                <div class="row gy-5">
                  <Agencyslider {...sliderSettings} className="customer-logos">
                    {agencylogoitems.map((items) => {
                      return (
                        <>
                          <div class="slide">
                            <img
                              className="imglogo"
                              width="125"
                              height="80"
                              src={
                                "https://api.myonventure.com/api/../agencylogo/" +
                                items.image
                              }
                            />
                          </div>
                        </>
                      );
                    })}
                  </Agencyslider>
                </div>
              </div>
            </section>
            <div className="container-fluid confused text-white mb-4">
              <h4>
                Are you finding media planning and buying to be a complex and
                overwhelming process?
              </h4>
              <p>
                You’re not alone—navigating the world of media can be
                challenging, especially when trying to create a cohesive and
                effective campaign that reaches your target audience across
                various platforms.{" "}
              </p>
              <Link
                to="/Findingmedia"
                className="btn bggreen btn-sm text-white text-bold"
              >
                LEARN MORE{" "}
                <i class="fas fa-angle-right m-1 me-md-2 bggreen p-1 text-white  rounded-circle"></i>
              </Link>
            </div>
            <div
              id="adhome2"
              class="carousel slide mt-0"
              data-bs-ride="carousel "
            >
              <div class="carousel-indicators">
                {adhome2.map((image1, index1) => (
                  <>
                    <button
                      type="button"
                      data-bs-target="#adhome2"
                      data-bs-slide-to={index1}
                      key={index1}
                      className={index1 === 0 ? "active" : ""}
                    ></button>
                  </>
                ))}
              </div>
              <div class="carousel-inner">
                {adhome2.map((item, index) => (
                  <div
                    key={item.id || index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      className="d-block w-100 topad"
                      src={`https://api.myonventure.com/api/../Advertisement/${item.image}`}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>

              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#adhome2"
                data-bs-slide="prev"
              >
                <span class="carousel-control-prev-icon"></span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#adhome2"
                data-bs-slide="next"
              >
                <span class="carousel-control-next-icon"></span>
              </button>
            </div>

            <section class="py-3 px-4 mt-5 bg-white py-md-5 py-xl-8 testmonial">
              <div class="container mb-0">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12">
                    <h1> What Our Clients Say</h1>
                    <p class=" mb-4">
                      Our valued clients' testimonials speak volumes about our
                      work's power.
                    </p>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary mb-2 mb-md-2 mb-xl-2 right"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>

              <div class="container overflow-hidden ">
                <div class="row gy-3 gy-lg-4">
                  <Testimonial
                    {...Testi_sliderSettings}
                    className="customer-logos"
                  >
                    {/* {clientlogoitems.map(items=>{return (<> */}

                    <div class="slide">
                      <div class="col-12 col-md-12 col-lg-12">
                        <div class="card  m-2">
                          <div class="card-body p-2 p-xxl-5">
                            <div
                              class="bsb-ratings text-warning mb-3"
                              data-bsb-star="5"
                              data-bsb-star-off="0"
                            ></div>
                            <blockquote class="bsb-blockquote-icon mb-3">
                              Nam ultricies, ex lacinia dapibus faucibus, sapien
                              ipsum euismod massa, at aliquet erat turpis quis
                              diam. Class aptent taciti sociosqu ad litora
                              torquent per conubia nostra, per inceptos
                              himenaeos.
                            </blockquote>
                            <figure class="d-flex align-items-center m-0 p-0">
                              <img
                                class="img-fluid rounded rounded-circle m-0 border border-5"
                                loading="lazy"
                                src={process.env.PUBLIC_URL + "/noimage.jpg"}
                                alt=""
                              />
                              <figcaption class="ms-3">
                                <h4 class="mb-1 h5">Luna John</h4>
                                <h5 class="fs-6 text-secondary mb-0">
                                  UX Designer
                                </h5>
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="slide ">
                      <div class="col-12  col-md-12  col-lg-12">
                        <div class="card m-2">
                          <div class="card-body p-2 p-xxl-5">
                            <div
                              class="bsb-ratings text-warning mb-3"
                              data-bsb-star="4"
                              data-bsb-star-off="1"
                            ></div>
                            <blockquote class="bsb-blockquote-icon mb-3">
                              Nam ultricies, ex lacinia dapibus faucibus, sapien
                              ipsum euismod massa, at aliquet erat turpis quis
                              diam. Class aptent taciti sociosqu ad litora
                              torquent per conubia nostra, per inceptos
                              himenaeos.
                            </blockquote>
                            <figure class="d-flex align-items-center m-0 p-0">
                              <img
                                class="img-fluid rounded rounded-circle m-0 border border-5"
                                loading="lazy"
                                src={process.env.PUBLIC_URL + "/noimage.jpg"}
                                alt=""
                              />
                              <figcaption class="ms-3">
                                <h4 class="mb-1 h5">Mark Smith</h4>
                                <h5 class="fs-6 text-secondary mb-0">
                                  Marketing Specialist
                                </h5>
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="slide">
                      {" "}
                      <div class="col-12  col-md-12  col-lg-12">
                        <div class="card  m-2">
                          <div class="card-body p-2 p-xxl-5">
                            <div
                              class="bsb-ratings text-warning mb-3"
                              data-bsb-star="3"
                              data-bsb-star-off="2"
                            ></div>
                            <blockquote class="bsb-blockquote-icon mb-3">
                              Nam ultricies, ex lacinia dapibus faucibus, sapien
                              ipsum euismod massa, at aliquet erat turpis quis
                              diam. Class aptent taciti sociosqu ad litora
                              torquent per conubia nostra, per inceptos
                              himenaeos.
                            </blockquote>
                            <figure class="d-flex align-items-center m-0 p-0">
                              <img
                                class="img-fluid rounded rounded-circle m-0 border border-5"
                                loading="lazy"
                                src={process.env.PUBLIC_URL + "/noimage.jpg"}
                                alt=""
                              />
                              <figcaption class="ms-3">
                                <h4 class="mb-1 h5">Michael Wilson</h4>
                                <h5 class="fs-6 text-secondary mb-0">
                                  SEO Expert
                                </h5>
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="slide">
                      <div class="col-12  col-md-12  col-lg-12">
                        <div class="card  m-2">
                          <div class="card-body p-2 p-xxl-5">
                            <div
                              class="bsb-ratings text-warning mb-3"
                              data-bsb-star="5"
                              data-bsb-star-off="0"
                            ></div>
                            <blockquote class="bsb-blockquote-icon mb-3">
                              Nam ultricies, ex lacinia dapibus faucibus, sapien
                              ipsum euismod massa, at aliquet erat turpis quis
                              diam. Class aptent taciti sociosqu ad litora
                              torquent per conubia nostra, per inceptos
                              himenaeos.
                            </blockquote>
                            <figure class="d-flex align-items-center m-0 p-0">
                              <img
                                class="img-fluid rounded rounded-circle m-0 border border-5"
                                loading="lazy"
                                src={process.env.PUBLIC_URL + "/noimage.jpg"}
                                alt=""
                              />
                              <figcaption class="ms-3">
                                <h4 class="mb-1 h5">Luke Reeves</h4>
                                <h5 class="fs-6 text-secondary mb-0">
                                  Sales Manager
                                </h5>
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*</>)  })} */}
                  </Testimonial>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
