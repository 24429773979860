import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const EventsPlanning = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve cart data from localStorage on component mount
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="header">
      <div className="container-fluid ">
        <div className="row  innerheader"></div>
        <div className="container">
          <h2 className="mb-4">Events Planning</h2>
          <div className="row">
            <p>
              Event planning for corporates and companies involves organizing
              and managing various types of business events, such as
              conferences, seminars, product launches, trade shows, corporate
              retreats, and team-building activities. Here’s a breakdown of the
              key steps involved in planning such events:
            </p>

            <ol>
              <li>
                {" "}
                <b>Defining Objectives and Goals</b>
                <br />
                <br />
                Understand the purpose of the event: Is it to build brand
                awareness, launch a new product, train employees, or celebrate
                milestones?
                <br />
                Define clear goals such as increasing sales, networking,
                educating attendees, or strengthening relationships with
                clients.
              </li>
              <br />
              <br />

              <li>
                <b> Budget Planning </b>
                <br />
                <br />
                Develop a detailed budget that includes all costs (venue,
                catering, equipment, transportation, marketing, etc.).
                <br />
                Prioritize spending based on the event's goals and negotiate
                with vendors for better deals.
              </li>
              <br />
              <br />

              <li>
                {" "}
                <b>Venue Selection </b>
                <br />
                <br />
                Choose a venue based on the size of the event, target audience,
                and logistical requirements (e.g., tech infrastructure,
                accessibility).
                <br />
                Consider factors like location, parking, facilities, and
                ambiance that align with the company's brand and objectives.
              </li>
              <br />
              <br />

              <li>
                <b> Event Design and Theme</b>
                <br />
                <br />
                Develop a theme or concept that resonates with the audience and
                aligns with the corporate identity.
                <br />
                Plan the event décor, lighting, stage setup, and seating
                arrangements to enhance the attendee experience.{" "}
              </li>
              <br />
              <br />

              <li>
                <b> Logistics and Operations </b>
                <br />
                <br />
                Handle all logistical aspects, such as transportation,
                accommodation for guests (if needed), and managing staff or
                volunteers for the event.
                <br />
                Arrange for audio-visual equipment, lighting, sound systems, and
                internet access.
                <br />
                Ensure security and crowd management plans are in place.
              </li>
              <br />
              <br />

              <li>
                <b>Program Development </b>
                <br />
                <br />
                Create an agenda that includes keynote speakers, panel
                discussions, workshops, entertainment, and networking
                opportunities.
                <br />
                Ensure the content is engaging and provides value to the
                attendees.
              </li>
              <br />
              <br />

              <li>
                {" "}
                <b>Marketing and Promotion</b>
                <br />
                <br />
                Promote the event through various channels such as social media,
                email campaigns, press releases, and partnerships.
                <br />
                Use targeted marketing to reach the right audience, focusing on
                key stakeholders, clients, or industry professionals.
              </li>
              <br />
              <br />

              <li>
                <b> Vendor and Supplier Management</b>
                <br />
                <br />
                Coordinate with vendors for catering, décor, printing (banners,
                invitations), and entertainment. Build
                <br />
                strong relationships with reliable suppliers to ensure
                everything runs smoothly.
              </li>
              <br />
              <br />

              <li>
                <b>Registration and Ticketing </b>
                <br />
                <br />
                Set up a registration system, either online or offline, for
                attendees.
                <br />
                Manage ticketing, RSVPs, and any special requests or
                accommodations needed by attendees.
              </li>
              <br />
              <br />

              <li>
                <b> On-Site Management </b>
                <br />
                <br />
                Be present during the event to ensure that everything runs
                according to plan.
                <br />
                Manage last-minute changes, troubleshoot any issues, and oversee
                staff and volunteers.
              </li>
              <br />
              <br />

              <li>
                {" "}
                <b>Post-Event Activities </b>
                <br />
                <br />
                Gather feedback from attendees to assess the success of the
                event and identify areas for improvement.
                <br />
                Thank attendees, sponsors, and vendors with follow-up emails,
                photos, or videos from the event.
                <br />
                Conduct a post-event analysis to review budget, attendance, and
                overall outcomes.
              </li>
              <br />
              <br />

              <li>
                <b> Sustainability and Corporate Responsibility </b>
                <br />
                <br />
                Plan eco-friendly events by reducing waste, using sustainable
                materials, and minimizing the event's carbon footprint.
                <br />
                Align the event with the company’s values by incorporating
                social responsibility initiatives, like charity drives or
                community involvement
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsPlanning;
