import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
function Customerorderitem({ itemId }) {
  const [productimageitems, setProductImageItems] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch product images for the given item ID

    fetchImages();
  }, [itemId]); // Re-fetch images whenever itemId changes
  const fetchImages = async () => {
    try {
      const userToken = localStorage.getItem("customeruser");
      if (!userToken) {
        navigate("/Login");
        return; // Exit early if the user is not logged in
      }
      const tokens = "1";

      const headers = { "Content-Type": "application/json" };
      const requestBody = {
        Email: userToken,
        orderno: itemId,
        Token: "1", // Include the token if necessary
      };

      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerOrder/CustomerOrderitem",
        requestBody,
        { headers }
      );

      setProductImageItems(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  return (
    <div>
      <table className="table table-bordered">
        <tr>
          <th>Sr no.</th> <th>Description</th> <th>Quantity</th> <th>Price</th>{" "}
          <th>Start Date</th> <th>End Date</th> <th>Delivery Status</th>{" "}
        </tr>
        {productimageitems.map((item, index) => (
          <tr key={item.id || index}>
            <td>{index + 1}</td> <td>{item.productname}</td>{" "}
            <td>{item.quantity}</td> <td>{item.price}</td>{" "}
            <td>{item.startdate}</td> <td>{item.enddate}</td>{" "}
            <td>{item.deliverystatus}</td>{" "}
          </tr>
        ))}
      </table>
    </div>
  );
}
export default Customerorderitem;
