import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Customermenu from "./Customermenu";

function Profileupdate() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",

    companyname: "",
    mobile: "",
    address: "",
    pan: "",
    gst: "",
    state: "",
    city: "",
    logo: null,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("customeruser");
  const [cityname, setcity] = useState("");
  const [statename, setstate] = useState("");
  const [cityOptions, setcityOptions] = useState([]);
  const [stateOptions, setstateOptions] = useState([]);
  // Placeholder for state and city options

  const fetchstateItems = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/state/state?tokens=" + tokens + ""
      );
      setstateOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchcityItems = async (id) => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/city/Stateid?id=" +
          id +
          "&tokens=" +
          tokens +
          ""
      );
      setcityOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const cityid = async (id) => {
    const headers = { "Content-Type": "application/json" };
    const requestBody = {
      tbl: "city",
      query: "where id= " + id,
      result: "name",
      Token: "1", // Include the token if necessary
    };

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerReport/getdata",
        requestBody,
        { headers }
      );
      console.log(response.data[0].name);
      /*  setFormData({
        city: response.data[0].name || "",
      }); */

      setcity(response.data[0].name);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const stateid = async (id) => {
    const headers = { "Content-Type": "application/json" };
    const requestBody = {
      tbl: "state",
      query: "where id= " + id,
      result: "name",
      Token: "1", // Include the token if necessary
    };

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerReport/getdata",
        requestBody,
        { headers }
      );
      console.log(response.data[0].name);
      /*  setFormData({
        city: response.data[0].name || "",
      }); */

      setstate(response.data[0].name);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const handleExitClick = async () => {
    setIsEditing(false);
  };
  useEffect(() => {
    fetchProfile();
  }, [navigate, userToken]);

  const fetchProfile = async () => {
    if (!userToken) {
      navigate("/Login");
      return;
    }

    fetchstateItems();

    try {
      const headers = { "Content-Type": "application/json" };
      const requestBody = {
        Email: userToken,
        Token: "1", // Include the token if necessary
      };
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerRegistration/Customerbyuser",
        requestBody,
        { headers }
      );
      console.log(response.data[0].logo);
      setFormData({
        id: response.data[0].id || "",
        email: response.data[0].email || "",

        companyname: response.data[0].companyname || "",
        mobile: response.data[0].mobile || "",
        address: response.data[0].address || "",
        pan: response.data[0].pan || "",
        gst: response.data[0].gst || "",
        state: response.data[0].state || "",
        city: response.data[0].city || "",
        logo: response.data[0].logo || "",
      });
      const cid = response.data[0].city;
      const sid = response.data[0].state;
      cityid(cid);
      stateid(sid);
      fetchcityItems(sid);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // fetchcityItems(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData);
    console.log(formData);
    setValidationErrors(errors);

    const headers = { "Content-Type": "multipart/form-data" };

    if (Object.keys(errors).length === 0) {
      setLoading(true); // Start loading state
      try {
        const response = await axios.post(
          "https://api.myonventure.com/api/CustomerRegistration/Update",
          formData,
          { headers: headers }
        );
        fetchProfile();
        setIsEditing(false); // Exit editing mode after successful update
        setFormData({}); // Reset form data if needed
        setValidationErrors({}); // Clear validation errors
        console.log("Profile updated successfully:", response.data);
      } catch (error) {
        console.error("Error updating profile data:", error);
        // Show error to user
      } finally {
        setLoading(false); // End loading state
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.email) errors.email = "Email is required.";

    if (!data.companyname) errors.companyname = "Company name is required.";
    if (!data.mobile) errors.mobile = "Mobile number is required.";
    if (!data.address) errors.address = "Address is required.";
    if (!data.pan) errors.pan = "PAN is required.";
    if (!data.gst) errors.gst = "GST is required.";
    return errors;
  };

  if (loading) {
    return <div>Loading...</div>; // Replace with your loading component
  }

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row innerheader">
          <div className="dashboard">
            <Customermenu />
            <div className="main-content">
              <h1>
                Your Profile{" "}
                <button
                  className="btn"
                  onClick={handleEditClick}
                  disabled={isEditing}
                >
                  <i className="fa fa-edit"></i> Edit
                </button>{" "}
                {isEditing && (
                  <p
                    className="left textpink "
                    style={{ fontSize: "18px", float: "right" }}
                  >
                    You are now editing Profile!
                  </p>
                )}
              </h1>
              {isEditing ? (
                <div className="card1">
                  <form className="user" onSubmit={handleSubmit}>
                    {Object.keys(validationErrors).length !== 0 && (
                      <p className="text-center">
                        <small className="text-danger alert alert-danger">
                          {Object.values(validationErrors).join(", ")}
                        </small>
                      </p>
                    )}
                    <div className="row">
                      {["companyname", "mobile", "address", "pan", "gst"].map(
                        (field) => (
                          <div
                            className="col-12 col-md-6 form-group"
                            key={field}
                          >
                            <label htmlFor={field}>
                              {field.charAt(0).toUpperCase() + field.slice(1)}
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id={field}
                              name={field}
                              placeholder={`Enter ${field}...`}
                              value={formData[field]}
                              onChange={handleInputChange}
                              required
                            />
                            {validationErrors[field] && (
                              <div className="invalid-feedback">
                                {validationErrors[field]}
                              </div>
                            )}
                          </div>
                        )
                      )}
                      <div className="col-12 col-md-6 form-group">
                        <label htmlFor="logo">Company Logo</label>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              logo: e.target.files[0],
                            })
                          }
                        />
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <label htmlFor="state">Select State</label>
                        <select
                          className="form-control"
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select an option</option>
                          {stateOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <label htmlFor="city">Select City</label>
                        <select
                          className="form-control"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select an option</option>
                          {cityOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-12 col-md-6 form-group mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                        >
                          Update Profile
                        </button>
                        &nbsp;{" "}
                        <button
                          className="btn btn-primary btn-user btn-block"
                          onClick={handleExitClick}
                        >
                          Exit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="summary">
                  <div className="card1">
                    <p>
                      <b>Email:</b> {formData.email}
                    </p>
                    <p>
                      <b>Company Name:</b> {formData.companyname}
                    </p>
                    <p>
                      <b>Mobile:</b> {formData.mobile}
                    </p>
                    <p>
                      <b>Address:</b> {formData.address}
                    </p>
                    <p>
                      {}
                      <b>City:</b> {cityname}
                    </p>
                    <p>
                      <b>State:</b> {statename}
                    </p>
                    <p>
                      <b>PAN:</b> {formData.pan}
                    </p>
                    <p>
                      <b>GST:</b> {formData.gst}
                    </p>
                    {formData.logo && (
                      <p>
                        <b>Logo:</b>{" "}
                        <img
                          src={
                            "https://api.myonventure.com/api/../customerlogo/" +
                            formData.logo
                          }
                        />
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profileupdate;
