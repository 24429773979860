import "./Contact.css";
function Contact() {
  return (
    <div className="header">
      <div className="container-fluid ">
        <div className="row   pt-0">
          <div class="container1 mt-0">
            <span class="big-circle"></span>
            <img src="img/shape.png" class="square" alt="" />
            <div class="form1">
              <div class="contact-info ">
                <h3 class="title">Let's get in touch</h3>
                <p class="text">
                  You can contact us via [your preferred method, e.g., email,
                  phone, social media]. Looking forward to hearing from you!
                </p>

                <div class="info">
                  <h3 class="title">Register Office</h3>
                  <div class="information1">
                    <i
                      class="fas fa-map-marker-alt "
                      style={{ margintop: "-10px" }}
                    ></i>{" "}
                    &nbsp; &nbsp;
                    <p>601 Royal Town Raghunath Vihar Sirsi Road Jaipur</p>
                  </div>
                  <h3 class="title">Corporate Office</h3>
                  <div class="information1">
                    <i
                      class="fas fa-map-marker-alt "
                      style={{ margintop: "-10px" }}
                    ></i>{" "}
                    &nbsp; &nbsp;
                    <p>
                      105 B Amish Park Mira Gaon Mira Road Thane Maharashtra
                    </p>
                  </div>
                  <div class="information">
                    <i class="fas fa-envelope"></i> &nbsp; &nbsp;
                    <p>info@myonventure.com</p>
                  </div>
                  <div class="information">
                    <i class="fas fa-phone"></i>&nbsp; &nbsp;
                    <p>+91-9571115669</p>
                  </div>
                </div>

                <div class="social-media">
                  <p>Connect with us :</p>
                  <div class="social-icons">
                    <a href="#">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="contact-form">
                <span class="circle one"></span>
                <span class="circle two"></span>

                <form action="index.html" className="form" autocomplete="off">
                  <h3 class="title">Contact us</h3>
                  <div class="input-container">
                    <input type="text" name="name" class="input1" />
                    <label for="">Username</label>
                    <span>Username</span>
                  </div>
                  <div class="input-container">
                    <input type="email" name="email" class="input1" />
                    <label for="">Email</label>
                    <span>Email</span>
                  </div>
                  <div class="input-container">
                    <input type="tel" name="phone" class="input1" />
                    <label for="">Phone</label>
                    <span>Phone</span>
                  </div>
                  <div class="input-container textarea">
                    <textarea name="message" class="input1"></textarea>
                    <label for="">Message</label>
                    <span>Message</span>
                  </div>
                  <input type="submit" value="Send" class="btn1" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
