import React, { useState, useEffect } from "react";
import axios from "axios";
/* import ProgressBar from "./ProgressBar"; // Import the ProgressBar component */
import Loading from "./Loading"; // Import the Loading component
import { Link, useLocation, useNavigate } from "react-router-dom";

import Customermenu from "./Customermenu";
import Customerorderitem from "./Customerorderitem";
import { format } from "date-fns";
const Customerorder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const receivedValue = params.get("value");
  const receivedname = params.get("name");
  const [crudOperation, setCrudOperation] = useState("read"); // Default to 'read'
  const [items, setItems] = useState([]);
  const [catname, setcatname] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };

  const userToken = localStorage.getItem("customeruser");

  //page loading start
  const [loading, setLoading] = useState(true);

  //progress bar start
  /*  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          return 100; // Prevent exceeding 100%
        }
        return Math.min(oldProgress + 10, 100); // Increment progress
      });
    }, 1000); // Update progress every second

    return () => clearInterval(interval); // Cleanup
  }, []); */
  //progress bar end
  useEffect(() => {
    if (!loading) {
      return <Loading />; // Show loading component while data is being fetched
    }
    // Check for userToken before proceeding
    if (!userToken) {
      navigate("/Login");
      return; // Exit early if the user is not logged in
    }

    fetchItems();
  }, []);

  const fetchItems = async () => {
    const headers = { "Content-Type": "application/json" };
    const requestBody = {
      Email: userToken,
      Token: "1", // Include the token if necessary
    };

    setLoading(true); // Set loading to true before the fetch

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerOrder/CustomerOrder",
        requestBody,
        { headers }
      );

      // Update items with the fetched data
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, you can set an error state here to inform the user
    } finally {
      setLoading(false); // Ensure loading is set to false after fetch
    }
  };

  return (
    <div className="header">
      <div className="container-fluid ">
        <div className="row  innerheader">
          <div className="dashboard">
            <Customermenu />
            <div className="main-content">
              {/*   <ProgressBar progress={progress} />
              <p>{progress}%</p> */}
              <h1> Your Orders</h1>

              <div className="summary">
                {items.map((item) => {
                  return (
                    <>
                      <div class="card bg-light mb-3">
                        <div class="card-header">
                          {" "}
                          Order No. : {item.Orderno} | Order Date:{" "}
                          {format(item.Orderdate, "dd-MM-yyyy")} | Total Qty :{" "}
                          {item.quantity} | Total Amount : {item.price}
                        </div>
                        <div class="card-body">
                          <Customerorderitem itemId={item.Orderno} />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customerorder;
