import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../componant/header.css";
import { Button } from "bootstrap";

function Header() {
  const [cartQuantity, setCartQuantity] = useState(0);
  const navigate = useNavigate();
  const [refreshHeader, setRefreshHeader] = useState(false); // State for header refresh
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  useEffect(() => {
    // Initial load
    loadCart();

    // Set up an interval to refresh cartQuantity every second
    const intervalId = setInterval(() => {
      loadCart();
    }, 1000); // 1000 ms = 1 second

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Function to load cart items from localStorage and update cartQuantity
  const loadCart = () => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const totalQuantity = storedCart.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    setCartQuantity(storedCart.length);
  };

  // Function to handle adding an item to cart
  const addToCart = (item) => {
    let storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    // Example: Adding an item (you need to implement this according to your application logic)
    storedCart.push(item);
    localStorage.setItem("cart", JSON.stringify(storedCart));

    // Trigger header refresh
    setRefreshHeader(true);
  };

  // Function to handle scroll behavior
  const scrollFunction = () => {
    const navbar = document.getElementById("navbar");
    if (navbar) {
      if (window.scrollY > 20) {
        navbar.style.background = "#fff";
      } else {
        navbar.style.background = "none";
      }
    }
  };

  useEffect(() => {
    // Attach scroll listener when component mounts
    window.addEventListener("scroll", scrollFunction);

    // Clean up function to remove scroll listener when component unmounts
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
    scrollToTop();
  }, []);
  const logout = () => {
    localStorage.removeItem("customeruser");
    localStorage.setItem("customeruser", "");
    console.log(localStorage.removeItem("customeruser"));
    navigate("/");
    return;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for a smooth scrolling effect
    });
  };

  const [inputText, setInputText] = useState("");
  /* const navigate = useNavigate(); */

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleButtonClick = () => {
    window.location.href = `/category?query= ${inputText}&qry=4`;
  };
  return (
    <div>
      <div id="navbar" className="p-3 text-center fixed-top bg-white">
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-2 col-sm-4 col-4">
              <a href="/" className="float-start">
                <img
                  src={process.env.PUBLIC_URL + "/hamarabrandlogo.png"}
                  height="45"
                  alt="Brand Logo"
                />
              </a>
            </div>
            <div className="order-lg-last col-lg-4 col-sm-8 col-8">
              <div className="d-flex float-end">
                {localStorage.getItem("customeruser") == "" && (
                  <Link
                    href="#"
                    className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
                    target="_blank"
                  >
                    <i className="fas fa-phone m-1 me-md-2 textpink"></i>
                    <p className="d-none  mb-0 textgreen">Helpline</p>
                  </Link>
                )}
                {localStorage.getItem("customeruser") != "" && (
                  <Link
                    alt="DASHBOARD"
                    to="/Dashboard"
                    className="me-1 border rounded py-1 px-3 nav-link pl-0 pr-0 d-flex align-items-center"
                  >
                    <i className="fas fa-home m-1 me-md-2 textpink"></i>
                    <p
                      className="d-none d-md-block mb-0 textgreen"
                      alt="DASHBOARD"
                    >
                      Dash..
                    </p>
                  </Link>
                )}
                {localStorage.getItem("customeruser") == "" && (
                  <Link
                    to="/Login"
                    className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
                  >
                    <i className="fas fa-sign-in m-1 me-md-2 textpink"></i>
                    <p className="d-none d-md-block mb-0 textgreen">Login</p>
                  </Link>
                )}

                <Link
                  to="/CheckoutPage"
                  className="border rounded py-1 px-3 nav-link d-flex align-items-center"
                >
                  <i className="fas fa-shopping-cart m-1 me-md-2 textpink"></i>
                  {cartQuantity > 0 && (
                    <span
                      className="badge text-dark"
                      style={{ position: "absolute", top: "10px" }}
                    >
                      {cartQuantity}
                    </span>
                  )}
                  <p className="d-none d-md-block mb-0 textgreen">My cart</p>
                </Link>
                {localStorage.getItem("customeruser") != "" && (
                  <Link
                    to="/Logout"
                    className="me-1 border rounded py-1 px-3 nav-link pl-0 pr-0 d-flex align-items-center"
                  >
                    <i className="fas fa-sign-out m-1 me-md-2 textpink"></i>
                    <p className="d-none d-md-block mb-0 textgreen">Logout</p>
                  </Link>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="input-group float-center">
                <div className="form-outline">
                  <input
                    type="text"
                    value={inputText}
                    onChange={handleInputChange}
                    className="form-control inp"
                    placeholder="Search"
                  />
                </div>
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="btn bggreen shadow-0 inpbtn"
                >
                  <i className="fas fa-search text-white "></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*    <div className="vendornet">VendorNet</div> */}
    </div>
  );
}

export default Header;
