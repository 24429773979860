import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.css";

const Customermenu = () => {
  return (
    <div className="sidebar">
      <h2>Dashboard</h2>
      <ul>
        <li>
          {localStorage.getItem("customeruser") != "" && (
            <Link
              to="/Dashboard"
              className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
            >
              <i className="fas fa-home m-1 me-md-2 textpink"></i>
              <p className="d-none d-md-block mb-0 textgreen">Dashbord</p>
            </Link>
          )}{" "}
        </li>
        <li>
          {" "}
          <Link
            to="/Profileupdate"
            className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
          >
            <i className="fas fa-user m-1 me-md-2 textpink"></i>
            <p className="d-none d-md-block mb-0 textgreen">Profile</p>
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/Customerorder"
            className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
          >
            <i className="fas fa-shopping-cart m-1 me-md-2 textpink"></i>
            <p className="d-none d-md-block mb-0 textgreen">Your Order</p>
          </Link>
        </li>
        <li>
          {" "}
          <Link
            to="/Changepassword"
            className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center"
          >
            <i className="fas fa-gears m-1 me-md-2 textpink"></i>
            <p className="d-none d-md-block mb-0 textgreen">Settings</p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Customermenu;
